'use strict';

/*global Modernizr */
import store from './_store';
import helpers from './_helpers';
import ResponsiveDebug from './class/ResponsiveDebug';
import Collapsible from './class/Collapsible';
import ScrollAnchor from './class/ScrollAnchor';
import ShowPassword from './class/ShowPassword';
import ClearInput from './class/ClearInput';
import DetectBrowser from './class/DetectBrowser';
import Cookies from './class/Cookies';
import BannerMessages from './class/BannerMessages';
import ValidForm from './class/ValidForm';

// import AnimatedLabelField from './class/AnimatedLabelField';


/**
 *
 * App
 * Main Controller
 *
 * @author acti
 * (vincegobelins, mha, efr, ...)
 */

const app = {
  init: function () {
    this.bindUI();

    /*--- initialisation des tailles de viewport ---*/
    store.currentWidth = store.wWidth = helpers.viewport().width;
    store.currentHeight = store.wHeight = helpers.viewport().height;

    store.wScroll = $(window).scrollTop();

    let self = this;


    /*--- responsive-debug ---*/
    let responsiveDebug = new ResponsiveDebug();

    /*--- detectBrowser ---*/
    let detectBrowser = new DetectBrowser();

    /*--- Validation Form ---*/
    let validForm = new ValidForm({
      /*container: '.valid-form',
      fieldContainer: '.form-field',
      validClass: 'as--valid',
      invalidClass: 'as--invalid',
      msgErrorClass: 'form-msg-error',*/
    });

    /*--- cookies ---*/
    store.cookies = new Cookies();

    /*--- Banner messages (cookies consent / warnig / news...) ---*/
    const messagesBanner = new BannerMessages(/*{
      //caping: 3,
    }*/);

    /*--- Skip links ---*/
    let skip = new Collapsible({
      container: '.skip',
      cta: '.skip-cta',
      className: 'as--focused',
      optionHover: true
    });

    /*--- colorbox ---*/
    /*let colorbox = new Colorbox();*/

    /*--- animation scroll ---*/
    /* Use '.js-scroll-anchor' class to trigger smooth anchor scroll*/
    store.scrollAnchor = new ScrollAnchor();

    /*--- password ---*/
    let showPassword = new ShowPassword();

    /*--- clear input ---*/
    let clearInput = new ClearInput();

    /*--- animated label ---*/
    // let form = new AnimatedLabelField();

    // responsive
    self.onResize();
  },

  checkMobile: function () {
    if (/Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent)) {
      return true;
    }
  },

  onResize: function () {
    let self = this;
  },

  onScroll: function () {
    let self = this;
  },

  bindUI: function () {
    let self = this;


    if (Modernizr.mq('only all')) {
      $(window).on('resize', function () {
        store.wWidth = helpers.viewport().width;
        store.wHeight = helpers.viewport().height;
        if (store.currentHeight !== store.wHeight || store.currentWidth !== store.wWidth) {
          store.currentHeight = store.wHeight;
          store.currentWidth = store.wWidth;
          /*--- timer pour le redimensionnement d'ecran ---*/
          clearTimeout(store.timerResponsive);
          store.timerResponsive = setTimeout(self.onResize.bind(self), 300);
        }
      });
    }


    $('.js-item-inview').each(function (i, itemInview) {
      const $itemView = $(itemInview);
      $itemView.one('inview', function (event, isInView) {
        if (isInView) {
          $itemView.addClass('is-inview');
        }
      });
    });

    if ($('form').length) {
      $(document).ready(function () {
        $('form .gform_button').addClass('a-button');
      });
    }

    if($('.login-connexion').length) {
      const passwordInput =  $('.login-connexion').find('input[type="password"]');
      $('.password-visibility').on('click', function() {
        if($(this).hasClass('pass-visible')) {
          passwordInput.get(0).type = 'password';
          $(this).removeClass('pass-visible');
        } else {
          passwordInput.get(0).type = 'text';
          $(this).addClass('pass-visible');
        }
      });
    }

   /* $('a.js-modal').click(function() {
     $(this).modal({
       fadeDuration: 250,
       closeText:'X'
     });
     return false;
   });*/

    // NAVBAR

    if ($('#header').length) {
      $('.burger').on('click', function (e) {
        if ($('.member-menu').hasClass('is-open')) {
          $('.member-menu').removeClass('is-open');
          $('#nav-icon').removeClass('open');
          $('body').removeClass('is-open');
          $('.member-container .member-open').removeClass('is-visible');
          $('.member-container .member-not-open').addClass('is-visible');
          $('.second-menu-container').removeClass('is-active');
        }
        $('.first-nav').toggleClass('is-open');
        $('.second-nav').removeClass('is-open');
        $('.nav-menu').toggleClass('nav-open');
        $(this).find('#nav-icon').toggleClass('open');
        $('body').toggleClass('is-open');
      });

      $('.member-container, .connect-bloc .connect-button, .link-connect').not('.disconnect').on('click', function (e) {
        if ($('.first-nav').hasClass('is-open') || $('.second-nav').hasClass('is-open')) {
          $('.first-nav').removeClass('is-open');
          $('.second-nav').removeClass('is-open');
          $('#nav-icon').removeClass('open');
          $('body').removeClass('is-open');
          $('.member-container .member-open').removeClass('is-visible');
          $('.member-container .member-not-open').addClass('is-visible');
          $('.second-menu-container').removeClass('is-active');
        }
        $('.member-menu.nav-menu-hidden').toggleClass('is-open');
        $('.member-container .member-not-open').toggleClass('is-visible');
        $('.member-container .member-open').toggleClass('is-visible');
        $('.nav-menu').toggleClass('nav-open');
        $('body').toggleClass('is-open');
      });

      $('.sub-menu').on('click', function (e) {
        $('.second-menu-container').removeClass('is-active');
        $('.second-nav').removeClass('presentation-option formation-option');
        $('.second-nav').toggleClass('is-open');
        $('.second-nav').addClass($(this).attr('id'));

        let pointer = '.' + $(this).attr('id');
        $('.second-nav ' + pointer).addClass('is-active');
      });

      $(document).on('click', function (event) {
        if ($(this.activeElement).is('.connect-button') || $(this.activeElement).is('.link-connect')) {
          return;
        }
        if (!$(event.target).closest('.header-inner').length) {
          $('body').removeClass('is-open');
          $('.first-nav').removeClass('is-open');
          $('.second-nav').removeClass('is-open');
          $('#nav-icon').removeClass('open');
          $('.member-menu').removeClass('is-open');
          $('.member-container .member-open').removeClass('is-visible');
          $('.member-container .member-not-open').addClass('is-visible');
        }
      });

      // $('.is-open').on('click', function () {
      //   console.log('meh');
      //   $('body').removeClass('is-open');
      //   $('.first-nav').removeClass('is-open');
      //   $('.second-nav').removeClass('is-open');
      //   $('#nav-icon').removeClass('open');
      //   $('.member-menu').removeClass('is-open');
      // });


      $('.return-button').on('click', function (e) {
        $(this).closest('.nav-menu-hidden').removeClass('is-open');
        $('.second-menu-container').removeClass('is-active');
      });

      $(document).ready(function () {
        if ($('.header-bloc-connexion #field_0_1').hasClass('gfield_error')) {
          $('.header-bloc-connexion .gform_wrapper').addClass('has-error');
        } else {
          $('.header-bloc-connexion .gform_wrapper').removeClass('has-error');
        }

        if ($('.header-bloc-connexion .gfield').hasClass('gfield_error')) {
          $('.member-menu').addClass('is-open');
          $('.member-container .member-open').addClass('is-visible');
          $('.member-container .member-not-open').removeClass('is-visible');
          $('body').toggleClass('is-open');
        }
      });
    }

    if ($('.button-share').length) {
      $('.share-button').on('click', function () {
        $(this).parent().find('.share-container').toggleClass('is-clicked');
      });
    }

    $(document).ready(function () {
      if ($('.member-menu .portrait-container img').attr('src') !== '') {
        $('.member-menu .portrait-container').addClass('has-img');
      }

      if ($('.portrait img').attr('src') !== '') {
        $('.portrait .img-container').addClass('has-img');
        $('.portrait .img').addClass('has-img');
      }

      if ($('.connect-bloc .member-profilpic img').length && $('.connect-bloc .member-profilpic img').attr('src') !== '' && $('.connect-bloc').hasClass('is-connected')) {
        $('.connect-bloc .member-profilpic').addClass('has-img');
      }

    });

    if ($('.switch-cards')) {
      $('.switch-button button').click(function () {
        var classButton = $(this).attr('class');
        $(this).addClass('is-clicked');
        $(this).siblings().removeClass('is-clicked');
        $('.switch-content>div').each(function (index) {
          var classContent = $(this).attr('class');
          if (classButton === classContent) {
            $(this).addClass('is-block');
            $(this).siblings().removeClass('is-block');
          }
        });
      });
    }

    if ($('.list-partners').length && window.matchMedia('(max-width: 896px)').matches) {
      $('.slider-partners').slick({
        infinite: true,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        arrows: false,
        dots: false,
        autoplay: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        useCSS: true,
        // prevArrow: $('.slider-partners').siblings('.title').find('.slick-prev'),
        // nextArrow: $('.slider-partners').siblings('.title').find('.slick-next'),
      });
    }

    if ($('.accordion-block')) {
      $('.accordion-title').on('click', function (e) {
        $(this).closest('li').toggleClass('is-open');
      });
    }

    if ($('.to-top').length) {
      $('.to-top').on('click', function (e) {
        window.scrollTo({top: 0, behavior: 'smooth'});
      });
    }

    if ($('.list-filters')) {
      var countCard = 0;
      var clickCountAll = 0;
      //Afficher les 12 premiers au chargement de la page
      $('.card-post').each(function (index) {
        if (countCard < 12) {
          $(this).addClass('is-block');
          countCard++;
        } else {
          return false;
        }
      });

      //Afficher 12 actus de plus au clique
      $('.more-button').click(function () {
        var maxCards = 12;
        countCard = 0;
        $('.card-post').each(function (index) {
          if (!$(this).hasClass('is-block')) {
            countCard++;
            if (countCard <= maxCards) {
              $(this).addClass('is-block');
            } else {
              return false;
            }
          }
        });
      });

      //Afficher au clique des filtres
      var clickCount = 0;
      $('.filters-button-group button').click(function () {
        $('.more-button').css('display', 'none');
        //enlever les class qu'une seule fois car il y a 9 actu "is block" par défaut
        if (clickCount === 0) {
          $('.card-post').removeClass('is-block');
          clickCount = 1;
        }

        //si click sur bouton toutes catégories
        if ($(this).attr('data-filter') === 'all' && clickCountAll === 0) {
          $('.card-post').addClass('is-block');
          $('.filters-button-group button').addClass('is-checked');
          clickCountAll = 1;
        }

        if ($(this).attr('data-filter') !== 'all' && clickCountAll === 0) {
          $('.see-all').removeClass('is-checked');
          //class sur les boutons cliqués
          if (!$(this).hasClass('is-checked')) {
            $(this).addClass('is-checked');
            //faire corréler les boutons cliqués avec les actus correspondantes
            $('button.is-checked').each(function (index) {
              var buttonFilter = $(this).attr('data-filter');
              $('.card-post').each(function (index) {
                if (!$(this).hasClass('is-block')) {
                  var cardClass = $(this).attr('data-filter');
                  if (buttonFilter === cardClass && buttonFilter !== 'all') {
                    $(this).addClass('is-block');
                  }
                }
              });
            });
          } else {
            $(this).removeClass('is-checked');
            var buttonFilter = $(this).attr('data-filter');
            $('.card-post').each(function (index) {
              var cardClass = $(this).attr('data-filter');
              if (buttonFilter === cardClass && buttonFilter !== 'all') {
                $(this).removeClass('is-block');
              }
            });
          }
        }

        //si click sur toutes catégories puis une catégorie
        if ($(this).attr('data-filter') !== 'all' && clickCountAll === 1) {
          var buttonFilter = $(this).attr('data-filter');
          $(this).siblings().removeClass('is-checked');
          $('.card-post').each(function (index) {
            var cardClass = $(this).attr('data-filter');
            if (buttonFilter === cardClass && buttonFilter !== 'all') {
              $(this).addClass('is-block');
            } else {
              $(this).removeClass('is-block');
            }
          });
          clickCountAll = 0;
        }

        $('.filters-button-group button').each(function (index) {
          if (!$('.filters-button-group button.is-checked').length) {
            $('.filters-button-group button').addClass('is-checked');
            $('.card-post').addClass('is-block');
            clickCount = 1;
          }
        });

      });
    }

    if ($('.number-bloc').length) {
      const counter = $('.count');
      let countObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            $('.count:not(.js-done)').each(function () {
              $('.count').addClass('js-done');
              $(this).prop('Counter', 0).animate({
                Counter: $(this).text()
              }, {
                duration: 4000,
                easing: 'swing',
                step: function (now) {
                  if ($.isNumeric(now) === false) {
                  } else {
                    let result = Math.ceil(now).toLocaleString();
                    $(this).text(result);
                  }

                }
              });
            });
          }
        });
      });
      countObserver.observe(counter[0]);
    }


    // ANNUAIRE

    if ($('.page-annuaire').length) {
      $(document).ready(function () {
        $('.no-result-bloc').fadeOut('fast');

        // store filter for each group
        var buttonFilters = {};
        var buttonFilter;
// quick search regex
        var qsRegex;

// init Isotope
        var $grid = $('.annuaire-grid').isotope({
          // resizable: false,
          layoutMode: 'fitRows',
          itemSelector: '.card-annuaire',
          filter: function () {
            var $this = $(this);
            console.log(qsRegex);
            var firstLetter = $($this).find('.name').text().charAt(0);
            var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
            var buttonResult = buttonFilter ? firstLetter === buttonFilter : true;
            return searchResult && buttonResult;
          },
        });

        $('.filters').on('click', '.button-group .a-button', function () {
          var $this = $(this);
          var $buttonGroup = $this.parents('.button-group');
          var filterGroup = $buttonGroup.attr('data-filter-group');
          // set filter for group
          buttonFilters[filterGroup] = $this.attr('data-filter');
          // combine filters
          buttonFilter = concatValues(buttonFilters);
          // Isotope arrange
          $grid.isotope();

          if (!$grid.data('isotope').filteredItems.length) {
            $('.no-result-bloc').fadeIn('slow');
          } else {
            $('.no-result-bloc').fadeOut('fast');
          }

        });

        $('.button--reset').on('click', function () {
          buttonFilters = {};
          buttonFilter = '';
          $('.button-group .a-button').removeClass('is-checked');
          $('.quicksearch').val('');
          $('.quicksearch').keyup();

          if (!$grid.data('isotope').filteredItems.length) {
            $('.no-result-bloc').fadeIn('slow');
          } else {
            $('.no-result-bloc').fadeOut('fast');
          }
        });
// use value of search field to filter
        var $quicksearch = $('.quicksearch').keyup(debounce(function () {
          qsRegex = new RegExp($quicksearch.val(), 'gi');
          $grid.isotope();

          if (!$grid.data('isotope').filteredItems.length) {
            $('.no-result-bloc').fadeIn('slow');
          } else {
            $('.no-result-bloc').fadeOut('fast');
          }
        }));

// change is-checked class on buttons
        $('.button-group').each(function (i, buttonGroup) {
          var $buttonGroup = $(buttonGroup);
          $buttonGroup.on('click', 'button', function () {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $(this).addClass('is-checked');
          });
        });

// flatten object by concatting values
        function concatValues(obj) {
          var value = '';
          for (var prop in obj) {
            value += obj[prop];
          }
          return value;
        }

// debounce so filtering doesn't happen every millisecond
        function debounce(fn, threshold) {
          var timeout;
          threshold = threshold || 100;
          return function debounced() {
            clearTimeout(timeout);
            var args = arguments;
            var _this = this;

            function delayed() {
              fn.apply(_this, args);
            }

            timeout = setTimeout(delayed, threshold);
          };
        }
      });
    }

    if ($('.page-profil').length) {
      if (parseInt($(window).width()) < 897) {
        $('#main-content form .gfield').removeClass('gfield--width-half');
        $('#main-content form .gfield').addClass('gfield--width-full');
      }
    }


    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        self.onResize();
      }
    };

    /*--- fonctions au scroll ---*/
    $(window).on('scroll', function () {
      store.wScroll = $(window).scrollTop();

      self.onScroll();
    });

      $('.card-annuaire').hover(function () {
        //if only on desktop (larger than 896px)
        if (window.matchMedia('(min-width: 897px)').matches) {
          var tooltipId = $(this).data('tooltip');
          var tooltipContent = $('#' + tooltipId).html();

          // Positionnement du tooltip au-dessus du curseur
          var mouseX = event.clientX;
          var mouseY = event.clientY;

          //Obtenir la taille du tooltip
          var tooltipWidth = $('#' + tooltipId).width();
          var tooltipHeight = $('#' + tooltipId).height();


          $('#' + tooltipId).css({
            'top': '190px',
            'left': '-' + tooltipWidth / 4 + 'px'
          });


          $('#' + tooltipId).fadeIn('fast');
        }
        }, function () {
          // Masquer le tooltip lorsqu'on quitte la carte
          $('.tooltip-content').fadeOut('fast');
        });
  }
};

app.init();

// global custom functions, they can be called from anywhere within the project (useful for the back-end developers)
let customFunctions = {
  // global custom function example
  // to call it from anywhere : global.customFunction.afterAjaxExample();
  /*afterAjaxExample: function() {
   helpers.resizeImg('.media-block-news');
   }*/
};
// exports the elements that need to be accessed from somewhere else (in the "global" standalone object, cf. gulpfile)
module.exports = customFunctions;

if($('.annuaire-contact-list').length){
  $('body').removeClass('edito-page');
  $('body').addClass('page-conseil_admin');
}
